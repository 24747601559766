<template>
  <div v-if="display === 'new_user'" class="text-center">
    <Heading type="h1" variant="h3">Hooray! 🎉</Heading>
    <p class="mt-3">Give us a moment while we create your co-branded experience . . .</p>
  </div>
  <div v-else-if="display === 'existing_user'" class="text-center">
    <Heading type="h1" variant="h3">Check your email!</Heading>
    <p class="mt-3">
      To get started, tap the magic link button in the email we sent to <strong>{{ data.email }}</strong> ✨
    </p>
  </div>
  <Form v-else :data="data" endpoint="attempt" hide-submit @success="onSuccess">
    <div class="space-y-4" :class="{ 'md:flex md:space-x-4 md:space-y-0': inline }">
      <InputGroup name="email" :class="{ 'md:grow': inline }">
        <TextInput v-model="data.email" type="email" name="email" :placeholder="action === 'register' ? 'your@email.com' : 'Email address'" auto-focus />
      </InputGroup>
      <div>
        <Button type="submit" :color="buttonColor" full-width>
          {{ buttonLabel || (action === "login" ? "Sign in" : "Create account") }}
        </Button>
      </div>
    </div>
  </Form>
</template>

<script setup lang="ts">
import type { SignupSource } from "composables/useSignup";

export type LoginOrRegisterResponse = {
  new_user: boolean;
  redirect_to: string | null;
  user: User | null;
};

const props = withDefaults(
  defineProps<{
    action?: "login" | "register";
    source?: SignupSource;
    collectionHashid?: string;
    partnerSlug?: string;
    redirectTo?: string;
    buttonColor?: ButtonColor;
    buttonLabel?: string;
    inline?: boolean;
  }>(),
  {
    action: "register",
  }
);

const emit = defineEmits(["success"]);

const display = ref<"form" | "new_user" | "existing_user">("form");

const data = ref({
  email: "",
  source: props.source,
  collection_hashid: props.collectionHashid,
  partner_slug: props.partnerSlug,
  redirect_to: props.redirectTo,
});

// Success handler
const onSuccess = async ({ new_user, redirect_to, user }: LoginOrRegisterResponse) => {
  display.value = new_user ? "new_user" : "existing_user";
  emit("success", { new_user, redirect_to, user });

  localStorage.setItem("show_modal", new_user ? "new_user" : "existing_user");

  if (new_user) {
    const { user: authUser } = useAuth();
    authUser.value = user;

    if (redirect_to) {
      await navigateTo(redirect_to, { replace: true });
    }
  }
};
</script>
